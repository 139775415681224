import React from "react";
import { Link } from "react-router-dom";
// import AccordionSubItem from "./AccordionSubItem";

const AccordionNavItem = ({ item, title }) => {
  
  // const [open, setOpen] = useState(false);

  // const handleOpen = (e) => {
  //   e.preventDefault();
  //   setOpen(!open);
  // };
  console.log(item)
  
  return (
  <li>
    <Link
      to={item.link}
      className="accordion-heading"
      data-toggle="collapse"
      // onClick={handleOpen}
    >
      {title}
    </Link>
    {/* <ul className={"side-bar-nav-list collapse " + (open ? "in" : "")}>
      {item.map((val, i) => (
        <AccordionSubItem item={val} index={i} key={val.id} />
      ))}
    </ul> */}
  </li>
)};

export default AccordionNavItem;