import React from "react";
// DATA Files
import dataNavbar from "../../data/Navbar/nav-main-data.json";
import dataPortfolio from "../../data/Portfolio/portfolio-two-data.json";
// Images
// import imgBg from "../../assets/images/slides/title-bg.jpg";
// Components
import NavbarRightSide from "../../components/Navbar/NavbarRightSide";
import PageTitleThree from "../../components/PageTitle/PageTitleThree";
import FooterTwo from "../../components/Footer/FooterTwo";
import PortfolioGrid from "../../components/Portfolio/PortfolioGrid";
import Loader from "../../components/Loader/Loader";

const PortfolioGridTwo = () => (
  <Loader>
    <NavbarRightSide data={dataNavbar} type="white" />
    <PageTitleThree
      title="Our Portfolio"
      tagline="Recent Works"
      image={'https://assets-global.website-files.com/606a802fcaa89bc357508cad/61701b4e166c837543d8f0ce_2_How%20To%20a%20Create%20Personal%20Portfolio%20Website.png'}
    />
    <PortfolioGrid data={dataPortfolio} />
    <FooterTwo />
  </Loader>
);

export default PortfolioGridTwo;
